export const cities = [
  "Tunis",
  "Ariana",
  "Ben Arous",
  "Mannouba",
  "Nabeul",
  "Zaghouan",
  "Bizerte",
  "Beja",
  "Jendouba",
  "Kef",
  "Siliana",

  "Kairouan",
  "Kasserine",
  "Sidi Bouzid",
  "Sousse",
  "Monastir",
  "Mahdia",
  "Sfax",
  "Gafsa",
  "Tozeur",
  "Kebili",
  "Gabes",
  "Medenine",
  "Tataouine",
];
