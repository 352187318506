export default class CustomerModel {
  fullName = "";
  address = "";
  email = "";
  city = "";
  gouvernerate = "";
  cityCode;
  zipCode = "";
  phoneNumber = "";
  phoneNumber2 = "";
}
