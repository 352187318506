export const ENDPOINTS = {
  Accounts: "Accounts",

  Auth: "Auth",

  Customer: "Customer",
  Delivery: "Delivery",
  Driver: "Driver",
  Store: "Store",
  Statistics: "Statistics",
};
